html, body {

  padding: 0;
  
  margin: 0;

  overflow-x: hidden;
  
  width: 100%;
  
  }